.TeeRedInput {
    padding: 0.5rem;
    height: 100%;
    width: 80%;
    border: none;
    font-size: larg;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.TeeRedInputCompleto {
    height: 100%;
    width: 100%;
    border: none;
    font-size: larg;
}

.NombreProyecto {
    width: 100%;
}

.InputContainer1 {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

#ComprobanteInput {
    margin: 0 !important;
}

#CheckInput {
    margin-left: 20%;
    margin-top: 30%;
}

.InputContainer4 {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.InputContainer12 {
    padding: 0.5rem;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.InputContainer12>div {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
}

.InputContainer12>div:first-child>div {
    text-align: center;
}

.InputContainer12>div:nth-child(3)>div {
    text-align: center;
}

.InputContainer5 {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.InputContainer3 {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.InputContainer2 {
    padding: 0.5rem;
    height: 7%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.InputTextArea {
    height: 7%;
    padding: 0;
}

.AlumnoTextArea {
    resize: none;
    height: 80%;
}

.AlumnoGroupTextArea {
    height: 100%;
}

.InputContainer4-2 {
    display: flex;
    flex-direction: row;
}

.InputContainer2Columns {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-height: 14%;
}

.InputSelect {
    border: solid 1px #cccccc;
    padding: 0;
    border-radius: 0.5rem;
}

.mi-modal-personalizado .modal-content {
    width: 90vw;
    margin: 0;
    height: 90vh;
    margin-top: 5vh;
}

#modalAlumnoR {
    margin: 0;
}

.mi-modal-personalizado .modal-body {
    padding: 10px;
    padding-top: 0;
}

.modalAlumnoActualizar .modal-content {
    width: 87vw;
    margin: 0;
    height: 90vh;
    margin-top: 0vh;
    border: none;
}

.modalAlumnoActualizar .modal-body {

    padding-top: 0;
}

.menorButton {
    padding: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    width: 40%;
    border-radius: 0.5rem;
    transition: transform 0.3s;
    background-color: white;
    text-align: center;
    border: black 1px solid;
    cursor: pointer;
}

.menorButton:hover {
    transform: scale(1.03);
    /* Hacemos que el botón se agrande un 5% cuando se hace hover */
    color: #f2f2f2;
    background-color: #333;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.AlumnoInfoMain {
    background-color: rgb(255, 255, 255);
    height: 100%;
    width: 100%;
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
}

.AlumnoInfoLeft {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 100%;
    width: 70%;
    border: solid 0px red;
}

.AlumnoInfoRight {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    border: solid 0px red;
}

.AlumnoInfoBottomInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;
    width: 100%;
    border: solid 0px red;
    padding-left: 0.5rem;
}

.AlumnoInfoProfilePicture {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 0px 9px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 0px 9px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 0px 9px rgba(0, 0, 0, 0.08);
}

.AlumnoInfoTitleInfo {
    width: 100%;
    border: solid 0px red;
    height: 20%;
    display: flex;
    flex-direction: column;
}

.AlumnoInfoTitleInfo p:first-child {
    font-weight: bold;
    height: 35%;
    margin: 0;
}

.AlumnoInfoTitleInfo p:nth-child(2) {
    height: 65%;
    margin: 0;
}

.AlumnoInfoObservaciones {
    height: 80%;
}

.AlumnoInfoInfoCentro {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
}

.CalendarioMain {
    height: 90%;
    width: 100%;
    /* border: dotted 3px #333; */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    transition: transform 0.3s;
}

.CalendarioMain:hover {
    transform: scale(1.01);
}

.CalendarioTitulo {
    padding-top: 0.5rem;
    background-color: #ebebeb;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.CalendarioTituloMes {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 0.5rem;
}

.CalendarioTituloDias {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.CalendarioTituloDia {
    width: 14.28%;
    text-align: center;
    font-size: 12px;
}

.CalendarioDiasMain {
    height: 70%;
    width: 100%;
    border-top: solid 1px #333;
}

.CalendarioDias {
    width: 100%;
    height: 16.6%;
    display: flex;
    border-bottom: solid 1px #333;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    padding-inline: 0rem;
}

.CalendarioDia {
    height: 100%;
    width: 14.28%;
    margin: 0;
    text-align: center;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform 0.3s;
    border-radius: 0.5rem;
}

.diaActivo {}

.diaInactivo {
    background-color: #cccccc;
    cursor: not-allowed;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.diaInactivo:hover {
    transform: scale(1.00) !important;
}

.diaCumplido {
    background-color: rgb(61, 173, 61);
    color: #ececec;
}

.diaFalta {
    background-color: rgb(204, 37, 59);
    color: #ececec;
}

.CalendarioDia:hover {
    transform: scale(1.06);
}

.CalendarioCirculo {
    width: 4%;
    height: 54%;
    border-radius: 100%;
    margin-right: 10px;
    margin-left: 3px;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.InstrumentosAdd {
    width: 20%;
    height: 100%;
    border: solid 1px green;
    display: flex;
    border-radius: 0.5rem;
    color: green;
    padding-left: 10;
    padding-right: 10;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 18px;
    font-weight: bold;
}

.InstrumentosAdd:hover {
    transform: scale(1.01);
    background-color: green;
    color: white;
}

.InstrumentosSub {
    width: 20%;
    height: 100%;
    border: solid 1px red;
    display: flex;
    border-radius: 0.5rem;
    color: red;
    padding-left: 10;
    padding-right: 10;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-right: 1rem;
}

.InstrumentosSub:hover {
    transform: scale(1.01);
    background-color: red;
    color: white;
}

.MaestroInstrumentosContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;

}

.MaestroInstrumentosContainer>div:first-child {
    margin-right: 20px;
}

.InstrumentosContainer {
    width: 35%;
    height: auto;
    min-height: 30vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    padding: 5px;

}

.InstrumentosContainer div {
    flex-basis: calc(33.33% - 10px);
    box-sizing: border-box;
    margin-bottom: 10px;
}

.InstrumentosInstrumento {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    text-align: center;
    padding: 2%;
    cursor: pointer;
    transition: 0.3s;
}

.InstrumentosInstrumento:hover {
    transform: scale(1.08);
    background-color: #333;
    color: #f2f2f2;
}

.InstrumentosInstrumentoTitulo {
    width: 100%;
    height: 10%;
    border-bottom: #333 solid 1px;
    text-align: center;
    text-align: center;
    font-size: 15px;
    font-weight: bold;

}

.PanelAsistencias {
    height: 93%;
    max-height: 407px;
    width: 100%;
    overflow: auto;
    padding: 10px;
}

.PanelClase {
    height: 50%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;
    border-radius: 1rem;
    padding-left: 5px;
    padding-right: 5px;
}

.PanelClase>div:last-child {
    border-bottom: none;
}

.PanelClase>div:first-child {
    border-bottom: solid 1px #333;
}

.PanelDia {
    height: 16%;
    width: 100%;
    border-bottom: solid 1px #333;
    display: flex;
    flex-direction: row;
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
}

.PanelDiaTitulo {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    font-size: 17px;
}

.PanelDiaTitulo>div:last-child {
    font-size: 12px;
    margin-top: -6px;
}

.PanelDiaFecha {
    /* border: solid red 1px; */
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.PanelDiaAsistencia {
    /* border: solid red 1px; */
    color: #f2f2f2;
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;

}

.PanelDiaAsistencia:hover {
    transform: scale(1.1);
    border-radius: 0.5rem;
}

.asiste {
    background-color: green;
}

.falta {
    background-color: red;
}

.PanelDiaCambiarAsistencia {
    /* border: solid red 1px; */
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MaestroTablaPagoMain {
    width: 100%;
    height: 17%;
    display: flex;
    flex-direction: row;
}

.MaestroPagoLeft {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #60D05E;
}

.MaestroPagoLeft>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    font-weight: bold;
}

.MaestroPagoLeft>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    color: white;
}

.MaestroPagoRight {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-weight: bold;
}

.MaestroPagoRight>div:first-child {
    height: 50%;
    width: 100%;
    background-color: #3D843B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MaestroPago {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    background-color: #4DA54B;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
}

.MaestroPago>div:first-child {
    width: 10%;
}

.MaestroPago>div:last-child {
    width: 90%;
    display: flex;
    justify-content: end;
}

/* -------------------------------------------------------- */

.PagoPorAlumnosMain {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.PagoPorAlumnosTitulo {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #EF8E27;
}

.PagoAlumnosHeaders {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: #FBB265;
}

.PagoAlumnosHeaders>div {
    width: 25%;
    text-align: center;
}

.PagoAlumnosHeaders>div:first-child {
    width: 5%;
}

.PagoAlumnosHeaders>div:nth-child(2) {
    width: 35%;
}

.PagoAlumnosHeaders>div:last-child {
    width: 35%;
}

.PagoAlumnoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #F1E091;
}

.PagoAlumnoRow>div {
    width: 25%;
    text-align: center;
}

.PagoAlumnoRow>div:first-child {
    width: 5%;
}

.PagoAlumnoRow>div:nth-child(2) {
    width: 35%;
}

.PagoAlumnoRow>div:last-child {
    width: 35%;
}

.PagoAlumnoTotal {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: #FBB265;
}

.PagoAlumnoTotal>div:first-child {
    width: 5%;
}

.PagoAlumnoTotal>div:nth-child(2) {
    width: 35%;
    text-align: center;
    background-color: #FBB265;
}

.PagoAlumnoTotal>div:last-child {
    width: 25%;
    text-align: center;
    background-color: #FBB265;
}


/*--------------------------------------------------------------------------------------*/

.DescuentoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #FFCDAC;
}

.DescuentoRow>div {
    width: 25%;
    text-align: center;
}

.DescuentoRow>div:first-child {
    width: 5%;
}

.DescuentoRow>div:nth-child(2) {
    width: 25%;
}

.DescuentoRow>div:last-child {
    width: 70%;
}

.DescuentoTotal {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: #FE7F70;
}

.DescuentoTotal>div:first-child {
    width: 5%;
}


.DescuentoTotal>div:last-child {
    width: 25%;
    text-align: center;
    background-color: #FE7F70;
}

input[value=""] {
    color: red;
}

select>option:first-child {
    color: red;
}



.editAluInfo {
    width: 40px;
    height: 40px;
    position: absolute;
    display: grid;
    place-items: center;
    top: 2%;
    left: 15%;
    z-index: 10;
    border-radius: 50%;
    padding: 5px;
    border: rgba(0, 0, 0, 0.15) solid 2px;
    color: rgba(0, 0, 0, 0.5);
}

.editAluInfo:hover{
    color: black;
    border: black solid 2px;
}

.imgAlumno {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.menosRepo{
    border: red 1px solid;
    border-radius: 1rem;
    height: 10px;
    width: 10px;
    display: grid;
    place-items: center;
    margin-right: 3px;
}