.ClasesMain{
    
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 1rem;
}

.HorarioCardMain{
    min-height: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    border-radius: 1rem;
}

.HorarioTop{
    background-color: #333333;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    color: #f7f7f7;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.HorarioTop > p:first-child{
    height: 100%;
    width: 20%;
}

.HorarioTop > p{
    display: grid;
    place-items: center;
}

.HorarioTop > p:nth-child(2){
    
    height: 100%;
    width: 60%;
}

.HorarioTop > p:last-child{
    
    height: 100%;
    width: 20%;
}

.HorarioBottom{
    padding-inline: 10px;
    width: 100%;
    height: 80%;
}