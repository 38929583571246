.chartContainerP{
    display: grid;
    place-items: center;
}
.totalPagos{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: small;
}

.totalEsperado{
    
}

.totalRecibido{
    font-size: x-large;
    color: green;

}

.totalFaltante{
    color: red;
}